/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCognitoUser = /* GraphQL */ `
  query GetCognitoUser($id: ID!) {
    getCognitoUser(id: $id) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const listCognitoUsers = /* GraphQL */ `
  query ListCognitoUsers(
    $filter: ModelCognitoUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCognitoUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      nextToken
    }
  }
`;
export const getAssetItemType = /* GraphQL */ `
  query GetAssetItemType($itemName: String!) {
    getAssetItemType(itemName: $itemName) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const listAssetItemTypes = /* GraphQL */ `
  query ListAssetItemTypes(
    $itemName: String
    $filter: ModelAssetItemTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssetItemTypes(
      itemName: $itemName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        itemName
        displayName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const assetItemTypesByActive = /* GraphQL */ `
  query AssetItemTypesByActive(
    $active: ItemTypeActive!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetItemTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetItemTypesByActive(
      active: $active
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemName
        displayName
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseId
        tenantOrganizationGroup
        borrowerId
        assetValueLoanFile
        assetValueUfgOverride
        recordSource
        assetItemType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const assetsByCase = /* GraphQL */ `
  query AssetsByCase(
    $caseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByCase(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        tenantOrganizationGroup
        borrowerId
        assetValueLoanFile
        assetValueUfgOverride
        recordSource
        assetItemType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const assetsByBorrower = /* GraphQL */ `
  query AssetsByBorrower(
    $borrowerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetsByBorrower(
      borrowerId: $borrowerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        tenantOrganizationGroup
        borrowerId
        assetValueLoanFile
        assetValueUfgOverride
        recordSource
        assetItemType
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIncomeItemType = /* GraphQL */ `
  query GetIncomeItemType($itemName: String!) {
    getIncomeItemType(itemName: $itemName) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIncomeItemTypes = /* GraphQL */ `
  query ListIncomeItemTypes(
    $itemName: String
    $filter: ModelIncomeItemTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIncomeItemTypes(
      itemName: $itemName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        itemName
        displayName
        active
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const incomeItemTypesByActive = /* GraphQL */ `
  query IncomeItemTypesByActive(
    $active: ItemTypeActive!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncomeItemTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    incomeItemTypesByActive(
      active: $active
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        itemName
        displayName
        active
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIncomeItem = /* GraphQL */ `
  query GetIncomeItem($id: ID!) {
    getIncomeItem(id: $id) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const listIncomeItems = /* GraphQL */ `
  query ListIncomeItems(
    $filter: ModelIncomeItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncomeItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        caseId
        owner
        tenantOrganizationGroup
        borrowerId
        recordSource
        incomeItemType
        incomeItemMonthlyAmtLoanFile
        incomeItemMonthlyAmtUfgOverride
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const incomeItemsByCase = /* GraphQL */ `
  query IncomeItemsByCase(
    $caseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncomeItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    incomeItemsByCase(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        owner
        tenantOrganizationGroup
        borrowerId
        recordSource
        incomeItemType
        incomeItemMonthlyAmtLoanFile
        incomeItemMonthlyAmtUfgOverride
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const incomeItemsByBorrower = /* GraphQL */ `
  query IncomeItemsByBorrower(
    $borrowerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIncomeItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    incomeItemsByBorrower(
      borrowerId: $borrowerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        caseId
        owner
        tenantOrganizationGroup
        borrowerId
        recordSource
        incomeItemType
        incomeItemMonthlyAmtLoanFile
        incomeItemMonthlyAmtUfgOverride
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBorrower = /* GraphQL */ `
  query GetBorrower($id: ID!) {
    getBorrower(id: $id) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const listBorrowers = /* GraphQL */ `
  query ListBorrowers(
    $filter: ModelBorrowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBorrowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const borrowerByCase = /* GraphQL */ `
  query BorrowerByCase(
    $caseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBorrowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    borrowerByCase(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLoanFile = /* GraphQL */ `
  query GetLoanFile($id: ID!) {
    getLoanFile(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const listLoanFiles = /* GraphQL */ `
  query ListLoanFiles(
    $filter: ModelLoanFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoanFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      nextToken
    }
  }
`;
export const loanFileByDate = /* GraphQL */ `
  query LoanFileByDate(
    $caseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoanFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loanFileByDate(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      nextToken
    }
  }
`;
export const loanFileByStatus = /* GraphQL */ `
  query LoanFileByStatus(
    $status: LoanFileStatus!
    $importedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLoanFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    loanFileByStatus(
      status: $status
      importedAt: $importedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      nextToken
    }
  }
`;
export const getCreditReport = /* GraphQL */ `
  query GetCreditReport($id: ID!) {
    getCreditReport(id: $id) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const listCreditReports = /* GraphQL */ `
  query ListCreditReports(
    $filter: ModelCreditReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantOrganizationGroup
        caseId
        creditProvider
        refNumber
        accountNo
        password
        status
        reportDate
        requestedAt
        respondedAt
        isJointReport
        creditReportPrincipalBorrowerId
        creditReportSecondaryBorrowerId
        principalBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        secondaryBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        creditReportPrincipalBorrowerDatabaseID
        creditReportSecondaryBorrowerIdDatabaseID
        databaseId
        createdAt
        requestedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        pdfS3Key
        loanFileID
        loanFileDatabaseID
        ingestionError
        updatedAt
        creditReportRequestedById
        owner
      }
      nextToken
    }
  }
`;
export const creditReportByCase = /* GraphQL */ `
  query CreditReportByCase(
    $caseId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditReportByCase(
      caseId: $caseId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        caseId
        creditProvider
        refNumber
        accountNo
        password
        status
        reportDate
        requestedAt
        respondedAt
        isJointReport
        creditReportPrincipalBorrowerId
        creditReportSecondaryBorrowerId
        principalBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        secondaryBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        creditReportPrincipalBorrowerDatabaseID
        creditReportSecondaryBorrowerIdDatabaseID
        databaseId
        createdAt
        requestedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        pdfS3Key
        loanFileID
        loanFileDatabaseID
        ingestionError
        updatedAt
        creditReportRequestedById
        owner
      }
      nextToken
    }
  }
`;
export const creditReportByPrincipalBorrower = /* GraphQL */ `
  query CreditReportByPrincipalBorrower(
    $creditReportPrincipalBorrowerId: ID!
    $requestedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditReportByPrincipalBorrower(
      creditReportPrincipalBorrowerId: $creditReportPrincipalBorrowerId
      requestedAt: $requestedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        caseId
        creditProvider
        refNumber
        accountNo
        password
        status
        reportDate
        requestedAt
        respondedAt
        isJointReport
        creditReportPrincipalBorrowerId
        creditReportSecondaryBorrowerId
        principalBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        secondaryBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        creditReportPrincipalBorrowerDatabaseID
        creditReportSecondaryBorrowerIdDatabaseID
        databaseId
        createdAt
        requestedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        pdfS3Key
        loanFileID
        loanFileDatabaseID
        ingestionError
        updatedAt
        creditReportRequestedById
        owner
      }
      nextToken
    }
  }
`;
export const creditReportBySecondaryBorrower = /* GraphQL */ `
  query CreditReportBySecondaryBorrower(
    $creditReportSecondaryBorrowerId: ID!
    $requestedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditReportBySecondaryBorrower(
      creditReportSecondaryBorrowerId: $creditReportSecondaryBorrowerId
      requestedAt: $requestedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        caseId
        creditProvider
        refNumber
        accountNo
        password
        status
        reportDate
        requestedAt
        respondedAt
        isJointReport
        creditReportPrincipalBorrowerId
        creditReportSecondaryBorrowerId
        principalBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        secondaryBorrower {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        creditReportPrincipalBorrowerDatabaseID
        creditReportSecondaryBorrowerIdDatabaseID
        databaseId
        createdAt
        requestedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        pdfS3Key
        loanFileID
        loanFileDatabaseID
        ingestionError
        updatedAt
        creditReportRequestedById
        owner
      }
      nextToken
    }
  }
`;
export const getAusDecision = /* GraphQL */ `
  query GetAusDecision($id: ID!) {
    getAusDecision(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const listAusDecisions = /* GraphQL */ `
  query ListAusDecisions(
    $filter: ModelAusDecisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAusDecisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        ausDecisionModule
        status
        submittedAt
        submittedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        errorCause
        processedAt
        ausDecisionRequestId
        ausDecisionResult
        ausDecisionResultDetails
        ausDecisionFindingsReportFilename
        createdAt
        updatedAt
        ausDecisionSubmittedById
      }
      nextToken
    }
  }
`;
export const ausDecisionByDate = /* GraphQL */ `
  query AusDecisionByDate(
    $caseId: ID!
    $submittedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAusDecisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ausDecisionByDate(
      caseId: $caseId
      submittedAt: $submittedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        ausDecisionModule
        status
        submittedAt
        submittedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        errorCause
        processedAt
        ausDecisionRequestId
        ausDecisionResult
        ausDecisionResultDetails
        ausDecisionFindingsReportFilename
        createdAt
        updatedAt
        ausDecisionSubmittedById
      }
      nextToken
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const listCases = /* GraphQL */ `
  query ListCases(
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseOrganizationId
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        caseNumber
        caseActiveLoanFileId
        activeLoanFile {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        loanFiles {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            fileName
            status
            uploadedAt
            importedAt
            databaseId
            urlKey
            updatedAt
            createdAt
            loanFileUploadedById
          }
          nextToken
        }
        creditReports {
          items {
            id
            tenantOrganizationGroup
            caseId
            creditProvider
            refNumber
            accountNo
            password
            status
            reportDate
            requestedAt
            respondedAt
            isJointReport
            creditReportPrincipalBorrowerId
            creditReportSecondaryBorrowerId
            creditReportPrincipalBorrowerDatabaseID
            creditReportSecondaryBorrowerIdDatabaseID
            databaseId
            createdAt
            pdfS3Key
            loanFileID
            loanFileDatabaseID
            ingestionError
            updatedAt
            creditReportRequestedById
            owner
          }
          nextToken
        }
        ausDecisions {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            ausDecisionModule
            status
            submittedAt
            errorCause
            processedAt
            ausDecisionRequestId
            ausDecisionResult
            ausDecisionResultDetails
            ausDecisionFindingsReportFilename
            createdAt
            updatedAt
            ausDecisionSubmittedById
          }
          nextToken
        }
        borrowers {
          items {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          nextToken
        }
        SONYMACaseDetails {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            attributeName
            displayName
            datatype
            prepopulatedValue
            overrideValue
            overrideType
            reference
            createdAt
            updatedAt
            updatedBy
          }
          nextToken
        }
        principalBorrower
        lastModifiedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        caseName
        caseStatus
        updatedAt
        createdAt
        program
        caseLastModifiedById
      }
      nextToken
    }
  }
`;
export const casesByOwner = /* GraphQL */ `
  query CasesByOwner(
    $owner: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    casesByOwner(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseOrganizationId
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        caseNumber
        caseActiveLoanFileId
        activeLoanFile {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        loanFiles {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            fileName
            status
            uploadedAt
            importedAt
            databaseId
            urlKey
            updatedAt
            createdAt
            loanFileUploadedById
          }
          nextToken
        }
        creditReports {
          items {
            id
            tenantOrganizationGroup
            caseId
            creditProvider
            refNumber
            accountNo
            password
            status
            reportDate
            requestedAt
            respondedAt
            isJointReport
            creditReportPrincipalBorrowerId
            creditReportSecondaryBorrowerId
            creditReportPrincipalBorrowerDatabaseID
            creditReportSecondaryBorrowerIdDatabaseID
            databaseId
            createdAt
            pdfS3Key
            loanFileID
            loanFileDatabaseID
            ingestionError
            updatedAt
            creditReportRequestedById
            owner
          }
          nextToken
        }
        ausDecisions {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            ausDecisionModule
            status
            submittedAt
            errorCause
            processedAt
            ausDecisionRequestId
            ausDecisionResult
            ausDecisionResultDetails
            ausDecisionFindingsReportFilename
            createdAt
            updatedAt
            ausDecisionSubmittedById
          }
          nextToken
        }
        borrowers {
          items {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          nextToken
        }
        SONYMACaseDetails {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            attributeName
            displayName
            datatype
            prepopulatedValue
            overrideValue
            overrideType
            reference
            createdAt
            updatedAt
            updatedBy
          }
          nextToken
        }
        principalBorrower
        lastModifiedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        caseName
        caseStatus
        updatedAt
        createdAt
        program
        caseLastModifiedById
      }
      nextToken
    }
  }
`;
export const casesByOrgByUpdateAt = /* GraphQL */ `
  query CasesByOrgByUpdateAt(
    $caseOrganizationId: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    casesByOrgByUpdateAt(
      caseOrganizationId: $caseOrganizationId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseOrganizationId
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        caseNumber
        caseActiveLoanFileId
        activeLoanFile {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        loanFiles {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            fileName
            status
            uploadedAt
            importedAt
            databaseId
            urlKey
            updatedAt
            createdAt
            loanFileUploadedById
          }
          nextToken
        }
        creditReports {
          items {
            id
            tenantOrganizationGroup
            caseId
            creditProvider
            refNumber
            accountNo
            password
            status
            reportDate
            requestedAt
            respondedAt
            isJointReport
            creditReportPrincipalBorrowerId
            creditReportSecondaryBorrowerId
            creditReportPrincipalBorrowerDatabaseID
            creditReportSecondaryBorrowerIdDatabaseID
            databaseId
            createdAt
            pdfS3Key
            loanFileID
            loanFileDatabaseID
            ingestionError
            updatedAt
            creditReportRequestedById
            owner
          }
          nextToken
        }
        ausDecisions {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            ausDecisionModule
            status
            submittedAt
            errorCause
            processedAt
            ausDecisionRequestId
            ausDecisionResult
            ausDecisionResultDetails
            ausDecisionFindingsReportFilename
            createdAt
            updatedAt
            ausDecisionSubmittedById
          }
          nextToken
        }
        borrowers {
          items {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          nextToken
        }
        SONYMACaseDetails {
          items {
            id
            tenantOrganizationGroup
            owner
            caseId
            attributeName
            displayName
            datatype
            prepopulatedValue
            overrideValue
            overrideType
            reference
            createdAt
            updatedAt
            updatedBy
          }
          nextToken
        }
        principalBorrower
        lastModifiedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        caseName
        caseStatus
        updatedAt
        createdAt
        program
        caseLastModifiedById
      }
      nextToken
    }
  }
`;
export const getSONYMACaseDetails = /* GraphQL */ `
  query GetSONYMACaseDetails($id: ID!) {
    getSONYMACaseDetails(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const listSONYMACaseDetails = /* GraphQL */ `
  query ListSONYMACaseDetails(
    $filter: ModelSONYMACaseDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSONYMACaseDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        attributeName
        displayName
        datatype
        prepopulatedValue
        overrideValue
        overrideType
        reference
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const sonymaCaseDetailsByCaseId = /* GraphQL */ `
  query SonymaCaseDetailsByCaseId(
    $caseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSONYMACaseDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sonymaCaseDetailsByCaseId(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        attributeName
        displayName
        datatype
        prepopulatedValue
        overrideValue
        overrideType
        reference
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCreditTradelines = /* GraphQL */ `
  query GetCreditTradelines($id: ID!) {
    getCreditTradelines(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const listCreditTradelines = /* GraphQL */ `
  query ListCreditTradelines(
    $filter: ModelCreditTradelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditTradelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        creditorName
        accountIdentifier
        status
        creditRepositorySource
        openedDate
        lastActivityDate
        creditLimitOrAmount
        accountType
        balance
        terms
        pastDue
        lateCount30
        lateCount60
        lateCount90
        lateCount120
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditTradelinesByCaseId = /* GraphQL */ `
  query CreditTradelinesByCaseId(
    $caseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTradelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditTradelinesByCaseId(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        creditorName
        accountIdentifier
        status
        creditRepositorySource
        openedDate
        lastActivityDate
        creditLimitOrAmount
        accountType
        balance
        terms
        pastDue
        lateCount30
        lateCount60
        lateCount90
        lateCount120
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditTradelinesByBorrowerId = /* GraphQL */ `
  query CreditTradelinesByBorrowerId(
    $borrowerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTradelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditTradelinesByBorrowerId(
      borrowerId: $borrowerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        creditorName
        accountIdentifier
        status
        creditRepositorySource
        openedDate
        lastActivityDate
        creditLimitOrAmount
        accountType
        balance
        terms
        pastDue
        lateCount30
        lateCount60
        lateCount90
        lateCount120
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCreditTradelinesAlternative = /* GraphQL */ `
  query GetCreditTradelinesAlternative($id: ID!) {
    getCreditTradelinesAlternative(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const listCreditTradelinesAlternatives = /* GraphQL */ `
  query ListCreditTradelinesAlternatives(
    $filter: ModelCreditTradelinesAlternativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditTradelinesAlternatives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        tradelineName
        openedDate
        balance
        monthlyPaymentAmount
        pastDue
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditTradelinesAlternativesByCaseId = /* GraphQL */ `
  query CreditTradelinesAlternativesByCaseId(
    $caseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTradelinesAlternativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditTradelinesAlternativesByCaseId(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        tradelineName
        openedDate
        balance
        monthlyPaymentAmount
        pastDue
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditTradelinesAlternativesByBorrowerId = /* GraphQL */ `
  query CreditTradelinesAlternativesByBorrowerId(
    $borrowerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditTradelinesAlternativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditTradelinesAlternativesByBorrowerId(
      borrowerId: $borrowerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        tradelineName
        openedDate
        balance
        monthlyPaymentAmount
        pastDue
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const getCreditPublicRecord = /* GraphQL */ `
  query GetCreditPublicRecord($id: ID!) {
    getCreditPublicRecord(id: $id) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const listCreditPublicRecords = /* GraphQL */ `
  query ListCreditPublicRecords(
    $filter: ModelCreditPublicRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreditPublicRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        publicRecordType
        filedDate
        dispositionType
        dispositionDate
        legalObligationAmount
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditPublicRecordsByCaseId = /* GraphQL */ `
  query CreditPublicRecordsByCaseId(
    $caseId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditPublicRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditPublicRecordsByCaseId(
      caseId: $caseId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        publicRecordType
        filedDate
        dispositionType
        dispositionDate
        legalObligationAmount
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
export const creditPublicRecordsByBorrowerId = /* GraphQL */ `
  query CreditPublicRecordsByBorrowerId(
    $borrowerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditPublicRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditPublicRecordsByBorrowerId(
      borrowerId: $borrowerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantOrganizationGroup
        owner
        caseId
        borrowerId
        publicRecordType
        filedDate
        dispositionType
        dispositionDate
        legalObligationAmount
        isOverridden
        createdAt
        updatedAt
        updatedBy
      }
      nextToken
    }
  }
`;
