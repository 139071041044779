import { useEffect, useState } from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Login } from '../login/Login';
import Home from '../home/home';

import { connect } from 'react-redux';
import { getAlert, addAlert, removeAlert } from '../../actions';

import { Message, Portal } from 'semantic-ui-react';

import { Amplify, Auth, autoShowTooltip } from 'aws-amplify';

Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

const App = (props: any) => {
  const [authState, setAuthState] = useState<{
    state: AuthState;
    user: object | undefined;
  }>({
    state: AuthState.SignedOut,
    user: undefined,
  });

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log('Updated 12.07')
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState({ state: nextAuthState, user: authData });
    });
  }, []);

  useEffect(() => {
    props.alert.message && portalOpen();
  }, [props.alert]);

  const showContent = () => {
    // if(props.loading)
    // {
    //     return (
    //         <Dimmer active inverted>
    //             <Loader size='mini'>Loading</Loader>
    //         </Dimmer>
    //     )
    // }

    if (authState.state === AuthState.SignedIn && authState.user)
      return <Home />;

    return <Login />;
  };

  const portalOpen = () => {
    setOpen(props.alert.showAlert);
    setTimeout(() => {
      setOpen(false);
      props.removeAlert();
    }, 2500);
  };
  //props.alert !=='undefined' ? props.alert.showAlert : false
  const messageType = (type: number | string) => {
    if (type === 'info') return 'blue';
    if (type === 0) return 'red';
    if (type === 1) return 'green';
    if (type === 2) return 'yellow';
  };

  return (
    <>
      {showContent()}

      <Portal open={open}>
        <div style={{ textAlign: 'center' }}>
          <Message
            className="tosterMessage"
            color={messageType(props.alert.type)}
            header={props.alert.header}
            style={{
              left: '50%',
              position: 'absolute',
              top: '114px',
              zIndex: 1000,
              //width:'200px',
              textAlign: 'center',
            }}
            content={props.alert.message}
          />
        </div>
      </Portal>
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    alert: state.alert && state.alert,
    loading: state.loading.loading,
  };
};

export default connect(mapStateToProps, { getAlert, addAlert, removeAlert })(
  App
);
