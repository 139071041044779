/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization($owner: String) {
    onCreateOrganization(owner: $owner) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization($owner: String) {
    onUpdateOrganization(owner: $owner) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization($owner: String) {
    onDeleteOrganization(owner: $owner) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCognitoUser = /* GraphQL */ `
  subscription OnCreateCognitoUser($owner: String) {
    onCreateCognitoUser(owner: $owner) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const onUpdateCognitoUser = /* GraphQL */ `
  subscription OnUpdateCognitoUser($owner: String) {
    onUpdateCognitoUser(owner: $owner) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const onDeleteCognitoUser = /* GraphQL */ `
  subscription OnDeleteCognitoUser($owner: String) {
    onDeleteCognitoUser(owner: $owner) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const onCreateAssetItemType = /* GraphQL */ `
  subscription OnCreateAssetItemType {
    onCreateAssetItemType {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAssetItemType = /* GraphQL */ `
  subscription OnUpdateAssetItemType {
    onUpdateAssetItemType {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAssetItemType = /* GraphQL */ `
  subscription OnDeleteAssetItemType {
    onDeleteAssetItemType {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset($owner: String) {
    onCreateAsset(owner: $owner) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset($owner: String) {
    onUpdateAsset(owner: $owner) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteAsset = /* GraphQL */ `
  subscription OnDeleteAsset($owner: String) {
    onDeleteAsset(owner: $owner) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateIncomeItemType = /* GraphQL */ `
  subscription OnCreateIncomeItemType($owner: String) {
    onCreateIncomeItemType(owner: $owner) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateIncomeItemType = /* GraphQL */ `
  subscription OnUpdateIncomeItemType($owner: String) {
    onUpdateIncomeItemType(owner: $owner) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteIncomeItemType = /* GraphQL */ `
  subscription OnDeleteIncomeItemType($owner: String) {
    onDeleteIncomeItemType(owner: $owner) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateIncomeItem = /* GraphQL */ `
  subscription OnCreateIncomeItem($owner: String) {
    onCreateIncomeItem(owner: $owner) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIncomeItem = /* GraphQL */ `
  subscription OnUpdateIncomeItem($owner: String) {
    onUpdateIncomeItem(owner: $owner) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIncomeItem = /* GraphQL */ `
  subscription OnDeleteIncomeItem($owner: String) {
    onDeleteIncomeItem(owner: $owner) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBorrower = /* GraphQL */ `
  subscription OnCreateBorrower($owner: String) {
    onCreateBorrower(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const onUpdateBorrower = /* GraphQL */ `
  subscription OnUpdateBorrower($owner: String) {
    onUpdateBorrower(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const onDeleteBorrower = /* GraphQL */ `
  subscription OnDeleteBorrower($owner: String) {
    onDeleteBorrower(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const onCreateLoanFile = /* GraphQL */ `
  subscription OnCreateLoanFile($owner: String) {
    onCreateLoanFile(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const onUpdateLoanFile = /* GraphQL */ `
  subscription OnUpdateLoanFile($owner: String) {
    onUpdateLoanFile(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const onDeleteLoanFile = /* GraphQL */ `
  subscription OnDeleteLoanFile($owner: String) {
    onDeleteLoanFile(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const onCreateCreditReport = /* GraphQL */ `
  subscription OnCreateCreditReport($owner: String) {
    onCreateCreditReport(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const onUpdateCreditReport = /* GraphQL */ `
  subscription OnUpdateCreditReport($owner: String) {
    onUpdateCreditReport(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const onDeleteCreditReport = /* GraphQL */ `
  subscription OnDeleteCreditReport($owner: String) {
    onDeleteCreditReport(owner: $owner) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const onCreateAusDecision = /* GraphQL */ `
  subscription OnCreateAusDecision($owner: String) {
    onCreateAusDecision(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const onUpdateAusDecision = /* GraphQL */ `
  subscription OnUpdateAusDecision($owner: String) {
    onUpdateAusDecision(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const onDeleteAusDecision = /* GraphQL */ `
  subscription OnDeleteAusDecision($owner: String) {
    onDeleteAusDecision(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const onCreateCase = /* GraphQL */ `
  subscription OnCreateCase($owner: String) {
    onCreateCase(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const onUpdateCase = /* GraphQL */ `
  subscription OnUpdateCase($owner: String) {
    onUpdateCase(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const onDeleteCase = /* GraphQL */ `
  subscription OnDeleteCase($owner: String) {
    onDeleteCase(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const onCreateSONYMACaseDetails = /* GraphQL */ `
  subscription OnCreateSONYMACaseDetails($owner: String) {
    onCreateSONYMACaseDetails(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onUpdateSONYMACaseDetails = /* GraphQL */ `
  subscription OnUpdateSONYMACaseDetails($owner: String) {
    onUpdateSONYMACaseDetails(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onDeleteSONYMACaseDetails = /* GraphQL */ `
  subscription OnDeleteSONYMACaseDetails($owner: String) {
    onDeleteSONYMACaseDetails(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onCreateCreditTradelines = /* GraphQL */ `
  subscription OnCreateCreditTradelines($owner: String) {
    onCreateCreditTradelines(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onUpdateCreditTradelines = /* GraphQL */ `
  subscription OnUpdateCreditTradelines($owner: String) {
    onUpdateCreditTradelines(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onDeleteCreditTradelines = /* GraphQL */ `
  subscription OnDeleteCreditTradelines($owner: String) {
    onDeleteCreditTradelines(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onCreateCreditTradelinesAlternative = /* GraphQL */ `
  subscription OnCreateCreditTradelinesAlternative($owner: String) {
    onCreateCreditTradelinesAlternative(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onUpdateCreditTradelinesAlternative = /* GraphQL */ `
  subscription OnUpdateCreditTradelinesAlternative($owner: String) {
    onUpdateCreditTradelinesAlternative(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onDeleteCreditTradelinesAlternative = /* GraphQL */ `
  subscription OnDeleteCreditTradelinesAlternative($owner: String) {
    onDeleteCreditTradelinesAlternative(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onCreateCreditPublicRecord = /* GraphQL */ `
  subscription OnCreateCreditPublicRecord($owner: String) {
    onCreateCreditPublicRecord(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onUpdateCreditPublicRecord = /* GraphQL */ `
  subscription OnUpdateCreditPublicRecord($owner: String) {
    onUpdateCreditPublicRecord(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const onDeleteCreditPublicRecord = /* GraphQL */ `
  subscription OnDeleteCreditPublicRecord($owner: String) {
    onDeleteCreditPublicRecord(owner: $owner) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
