/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const processLoanFileIngest = /* GraphQL */ `
  mutation ProcessLoanFileIngest($fileID: String!) {
    processLoanFileIngest(fileID: $fileID) {
      status
      cause
      isError
    }
  }
`;
export const processAusDecision = /* GraphQL */ `
  mutation ProcessAusDecision(
    $SASIDDecisionModuleName: String!
    $CaseID: String!
    $CaseNumber: String!
    $AusDecisionId: String!
  ) {
    processAusDecision(
      SASIDDecisionModuleName: $SASIDDecisionModuleName
      CaseID: $CaseID
      CaseNumber: $CaseNumber
      AusDecisionId: $AusDecisionId
    ) {
      status
      cause
      isError
    }
  }
`;
export const creditReportFileIngest = /* GraphQL */ `
  mutation CreditReportFileIngest(
    $creditReportId: String!
    $refNumber: String!
  ) {
    creditReportFileIngest(
      creditReportId: $creditReportId
      refNumber: $refNumber
    ) {
      status
      cause
      isError
    }
  }
`;
export const ausDecisionModules = /* GraphQL */ `
  mutation AusDecisionModules {
    ausDecisionModules
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      caseNumberPrefix
      tenantOrganizationGroup
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCognitoUser = /* GraphQL */ `
  mutation CreateCognitoUser(
    $input: CreateCognitoUserInput!
    $condition: ModelCognitoUserConditionInput
  ) {
    createCognitoUser(input: $input, condition: $condition) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const updateCognitoUser = /* GraphQL */ `
  mutation UpdateCognitoUser(
    $input: UpdateCognitoUserInput!
    $condition: ModelCognitoUserConditionInput
  ) {
    updateCognitoUser(input: $input, condition: $condition) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const deleteCognitoUser = /* GraphQL */ `
  mutation DeleteCognitoUser(
    $input: DeleteCognitoUserInput!
    $condition: ModelCognitoUserConditionInput
  ) {
    deleteCognitoUser(input: $input, condition: $condition) {
      id
      userName
      email
      group
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      cognitoUserOrganizationId
      owner
    }
  }
`;
export const createAssetItemType = /* GraphQL */ `
  mutation CreateAssetItemType(
    $input: CreateAssetItemTypeInput!
    $condition: ModelAssetItemTypeConditionInput
  ) {
    createAssetItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateAssetItemType = /* GraphQL */ `
  mutation UpdateAssetItemType(
    $input: UpdateAssetItemTypeInput!
    $condition: ModelAssetItemTypeConditionInput
  ) {
    updateAssetItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssetItemType = /* GraphQL */ `
  mutation DeleteAssetItemType(
    $input: DeleteAssetItemTypeInput!
    $condition: ModelAssetItemTypeConditionInput
  ) {
    deleteAssetItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset(
    $input: CreateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    createAsset(input: $input, condition: $condition) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset(
    $input: UpdateAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    updateAsset(input: $input, condition: $condition) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset(
    $input: DeleteAssetInput!
    $condition: ModelAssetConditionInput
  ) {
    deleteAsset(input: $input, condition: $condition) {
      id
      caseId
      tenantOrganizationGroup
      borrowerId
      assetValueLoanFile
      assetValueUfgOverride
      recordSource
      assetItemType
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIncomeItemType = /* GraphQL */ `
  mutation CreateIncomeItemType(
    $input: CreateIncomeItemTypeInput!
    $condition: ModelIncomeItemTypeConditionInput
  ) {
    createIncomeItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIncomeItemType = /* GraphQL */ `
  mutation UpdateIncomeItemType(
    $input: UpdateIncomeItemTypeInput!
    $condition: ModelIncomeItemTypeConditionInput
  ) {
    updateIncomeItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIncomeItemType = /* GraphQL */ `
  mutation DeleteIncomeItemType(
    $input: DeleteIncomeItemTypeInput!
    $condition: ModelIncomeItemTypeConditionInput
  ) {
    deleteIncomeItemType(input: $input, condition: $condition) {
      id
      itemName
      displayName
      active
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIncomeItem = /* GraphQL */ `
  mutation CreateIncomeItem(
    $input: CreateIncomeItemInput!
    $condition: ModelIncomeItemConditionInput
  ) {
    createIncomeItem(input: $input, condition: $condition) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const updateIncomeItem = /* GraphQL */ `
  mutation UpdateIncomeItem(
    $input: UpdateIncomeItemInput!
    $condition: ModelIncomeItemConditionInput
  ) {
    updateIncomeItem(input: $input, condition: $condition) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncomeItem = /* GraphQL */ `
  mutation DeleteIncomeItem(
    $input: DeleteIncomeItemInput!
    $condition: ModelIncomeItemConditionInput
  ) {
    deleteIncomeItem(input: $input, condition: $condition) {
      id
      caseId
      owner
      tenantOrganizationGroup
      borrowerId
      recordSource
      incomeItemType
      incomeItemMonthlyAmtLoanFile
      incomeItemMonthlyAmtUfgOverride
      createdAt
      updatedAt
    }
  }
`;
export const createBorrower = /* GraphQL */ `
  mutation CreateBorrower(
    $input: CreateBorrowerInput!
    $condition: ModelBorrowerConditionInput
  ) {
    createBorrower(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const updateBorrower = /* GraphQL */ `
  mutation UpdateBorrower(
    $input: UpdateBorrowerInput!
    $condition: ModelBorrowerConditionInput
  ) {
    updateBorrower(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const deleteBorrower = /* GraphQL */ `
  mutation DeleteBorrower(
    $input: DeleteBorrowerInput!
    $condition: ModelBorrowerConditionInput
  ) {
    deleteBorrower(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      fullName
      pgsqlBorrowerId
      sequence
      firstTimeHomebuyer
      incomeItems {
        items {
          id
          caseId
          owner
          tenantOrganizationGroup
          borrowerId
          recordSource
          incomeItemType
          incomeItemMonthlyAmtLoanFile
          incomeItemMonthlyAmtUfgOverride
          createdAt
          updatedAt
        }
        nextToken
      }
      assets {
        items {
          id
          caseId
          tenantOrganizationGroup
          borrowerId
          assetValueLoanFile
          assetValueUfgOverride
          recordSource
          assetItemType
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      rentalHistoryLast12M
      createdAt
      firstTimeHomebuyerOverride
      updatedAt
      owner
    }
  }
`;
export const createLoanFile = /* GraphQL */ `
  mutation CreateLoanFile(
    $input: CreateLoanFileInput!
    $condition: ModelLoanFileConditionInput
  ) {
    createLoanFile(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const updateLoanFile = /* GraphQL */ `
  mutation UpdateLoanFile(
    $input: UpdateLoanFileInput!
    $condition: ModelLoanFileConditionInput
  ) {
    updateLoanFile(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const deleteLoanFile = /* GraphQL */ `
  mutation DeleteLoanFile(
    $input: DeleteLoanFileInput!
    $condition: ModelLoanFileConditionInput
  ) {
    deleteLoanFile(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      fileName
      status
      uploadedAt
      importedAt
      databaseId
      uploadedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      urlKey
      updatedAt
      createdAt
      loanFileUploadedById
    }
  }
`;
export const createCreditReport = /* GraphQL */ `
  mutation CreateCreditReport(
    $input: CreateCreditReportInput!
    $condition: ModelCreditReportConditionInput
  ) {
    createCreditReport(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const updateCreditReport = /* GraphQL */ `
  mutation UpdateCreditReport(
    $input: UpdateCreditReportInput!
    $condition: ModelCreditReportConditionInput
  ) {
    updateCreditReport(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const deleteCreditReport = /* GraphQL */ `
  mutation DeleteCreditReport(
    $input: DeleteCreditReportInput!
    $condition: ModelCreditReportConditionInput
  ) {
    deleteCreditReport(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      caseId
      creditProvider
      refNumber
      accountNo
      password
      status
      reportDate
      requestedAt
      respondedAt
      isJointReport
      creditReportPrincipalBorrowerId
      creditReportSecondaryBorrowerId
      principalBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      secondaryBorrower {
        id
        tenantOrganizationGroup
        caseId
        fullName
        pgsqlBorrowerId
        sequence
        firstTimeHomebuyer
        incomeItems {
          items {
            id
            caseId
            owner
            tenantOrganizationGroup
            borrowerId
            recordSource
            incomeItemType
            incomeItemMonthlyAmtLoanFile
            incomeItemMonthlyAmtUfgOverride
            createdAt
            updatedAt
          }
          nextToken
        }
        assets {
          items {
            id
            caseId
            tenantOrganizationGroup
            borrowerId
            assetValueLoanFile
            assetValueUfgOverride
            recordSource
            assetItemType
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        rentalHistoryLast12M
        createdAt
        firstTimeHomebuyerOverride
        updatedAt
        owner
      }
      creditReportPrincipalBorrowerDatabaseID
      creditReportSecondaryBorrowerIdDatabaseID
      databaseId
      createdAt
      requestedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      pdfS3Key
      loanFileID
      loanFileDatabaseID
      ingestionError
      updatedAt
      creditReportRequestedById
      owner
    }
  }
`;
export const createAusDecision = /* GraphQL */ `
  mutation CreateAusDecision(
    $input: CreateAusDecisionInput!
    $condition: ModelAusDecisionConditionInput
  ) {
    createAusDecision(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const updateAusDecision = /* GraphQL */ `
  mutation UpdateAusDecision(
    $input: UpdateAusDecisionInput!
    $condition: ModelAusDecisionConditionInput
  ) {
    updateAusDecision(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const deleteAusDecision = /* GraphQL */ `
  mutation DeleteAusDecision(
    $input: DeleteAusDecisionInput!
    $condition: ModelAusDecisionConditionInput
  ) {
    deleteAusDecision(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      ausDecisionModule
      status
      submittedAt
      submittedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      errorCause
      processedAt
      ausDecisionRequestId
      ausDecisionResult
      ausDecisionResultDetails
      ausDecisionFindingsReportFilename
      createdAt
      updatedAt
      ausDecisionSubmittedById
    }
  }
`;
export const createCase = /* GraphQL */ `
  mutation CreateCase(
    $input: CreateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    createCase(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const updateCase = /* GraphQL */ `
  mutation UpdateCase(
    $input: UpdateCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    updateCase(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const deleteCase = /* GraphQL */ `
  mutation DeleteCase(
    $input: DeleteCaseInput!
    $condition: ModelCaseConditionInput
  ) {
    deleteCase(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseOrganizationId
      organization {
        id
        name
        caseNumberPrefix
        tenantOrganizationGroup
        createdAt
        updatedAt
        owner
      }
      caseNumber
      caseActiveLoanFileId
      activeLoanFile {
        id
        tenantOrganizationGroup
        owner
        caseId
        fileName
        status
        uploadedAt
        importedAt
        databaseId
        uploadedBy {
          id
          userName
          email
          group
          organization {
            id
            name
            caseNumberPrefix
            tenantOrganizationGroup
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
          cognitoUserOrganizationId
          owner
        }
        urlKey
        updatedAt
        createdAt
        loanFileUploadedById
      }
      loanFiles {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          fileName
          status
          uploadedAt
          importedAt
          databaseId
          uploadedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          urlKey
          updatedAt
          createdAt
          loanFileUploadedById
        }
        nextToken
      }
      creditReports {
        items {
          id
          tenantOrganizationGroup
          caseId
          creditProvider
          refNumber
          accountNo
          password
          status
          reportDate
          requestedAt
          respondedAt
          isJointReport
          creditReportPrincipalBorrowerId
          creditReportSecondaryBorrowerId
          principalBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          secondaryBorrower {
            id
            tenantOrganizationGroup
            caseId
            fullName
            pgsqlBorrowerId
            sequence
            firstTimeHomebuyer
            rentalHistoryLast12M
            createdAt
            firstTimeHomebuyerOverride
            updatedAt
            owner
          }
          creditReportPrincipalBorrowerDatabaseID
          creditReportSecondaryBorrowerIdDatabaseID
          databaseId
          createdAt
          requestedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          pdfS3Key
          loanFileID
          loanFileDatabaseID
          ingestionError
          updatedAt
          creditReportRequestedById
          owner
        }
        nextToken
      }
      ausDecisions {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          ausDecisionModule
          status
          submittedAt
          submittedBy {
            id
            userName
            email
            group
            createdAt
            updatedAt
            cognitoUserOrganizationId
            owner
          }
          errorCause
          processedAt
          ausDecisionRequestId
          ausDecisionResult
          ausDecisionResultDetails
          ausDecisionFindingsReportFilename
          createdAt
          updatedAt
          ausDecisionSubmittedById
        }
        nextToken
      }
      borrowers {
        items {
          id
          tenantOrganizationGroup
          caseId
          fullName
          pgsqlBorrowerId
          sequence
          firstTimeHomebuyer
          incomeItems {
            nextToken
          }
          assets {
            nextToken
          }
          rentalHistoryLast12M
          createdAt
          firstTimeHomebuyerOverride
          updatedAt
          owner
        }
        nextToken
      }
      SONYMACaseDetails {
        items {
          id
          tenantOrganizationGroup
          owner
          caseId
          attributeName
          displayName
          datatype
          prepopulatedValue
          overrideValue
          overrideType
          reference
          createdAt
          updatedAt
          updatedBy
        }
        nextToken
      }
      principalBorrower
      lastModifiedBy {
        id
        userName
        email
        group
        organization {
          id
          name
          caseNumberPrefix
          tenantOrganizationGroup
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        cognitoUserOrganizationId
        owner
      }
      caseName
      caseStatus
      updatedAt
      createdAt
      program
      caseLastModifiedById
    }
  }
`;
export const createSONYMACaseDetails = /* GraphQL */ `
  mutation CreateSONYMACaseDetails(
    $input: CreateSONYMACaseDetailsInput!
    $condition: ModelSONYMACaseDetailsConditionInput
  ) {
    createSONYMACaseDetails(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const updateSONYMACaseDetails = /* GraphQL */ `
  mutation UpdateSONYMACaseDetails(
    $input: UpdateSONYMACaseDetailsInput!
    $condition: ModelSONYMACaseDetailsConditionInput
  ) {
    updateSONYMACaseDetails(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const deleteSONYMACaseDetails = /* GraphQL */ `
  mutation DeleteSONYMACaseDetails(
    $input: DeleteSONYMACaseDetailsInput!
    $condition: ModelSONYMACaseDetailsConditionInput
  ) {
    deleteSONYMACaseDetails(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      attributeName
      displayName
      datatype
      prepopulatedValue
      overrideValue
      overrideType
      reference
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const createCreditTradelines = /* GraphQL */ `
  mutation CreateCreditTradelines(
    $input: CreateCreditTradelinesInput!
    $condition: ModelCreditTradelinesConditionInput
  ) {
    createCreditTradelines(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const updateCreditTradelines = /* GraphQL */ `
  mutation UpdateCreditTradelines(
    $input: UpdateCreditTradelinesInput!
    $condition: ModelCreditTradelinesConditionInput
  ) {
    updateCreditTradelines(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCreditTradelines = /* GraphQL */ `
  mutation DeleteCreditTradelines(
    $input: DeleteCreditTradelinesInput!
    $condition: ModelCreditTradelinesConditionInput
  ) {
    deleteCreditTradelines(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      creditorName
      accountIdentifier
      status
      creditRepositorySource
      openedDate
      lastActivityDate
      creditLimitOrAmount
      accountType
      balance
      terms
      pastDue
      lateCount30
      lateCount60
      lateCount90
      lateCount120
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const createCreditTradelinesAlternative = /* GraphQL */ `
  mutation CreateCreditTradelinesAlternative(
    $input: CreateCreditTradelinesAlternativeInput!
    $condition: ModelCreditTradelinesAlternativeConditionInput
  ) {
    createCreditTradelinesAlternative(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const updateCreditTradelinesAlternative = /* GraphQL */ `
  mutation UpdateCreditTradelinesAlternative(
    $input: UpdateCreditTradelinesAlternativeInput!
    $condition: ModelCreditTradelinesAlternativeConditionInput
  ) {
    updateCreditTradelinesAlternative(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCreditTradelinesAlternative = /* GraphQL */ `
  mutation DeleteCreditTradelinesAlternative(
    $input: DeleteCreditTradelinesAlternativeInput!
    $condition: ModelCreditTradelinesAlternativeConditionInput
  ) {
    deleteCreditTradelinesAlternative(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      tradelineName
      openedDate
      balance
      monthlyPaymentAmount
      pastDue
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const createCreditPublicRecord = /* GraphQL */ `
  mutation CreateCreditPublicRecord(
    $input: CreateCreditPublicRecordInput!
    $condition: ModelCreditPublicRecordConditionInput
  ) {
    createCreditPublicRecord(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const updateCreditPublicRecord = /* GraphQL */ `
  mutation UpdateCreditPublicRecord(
    $input: UpdateCreditPublicRecordInput!
    $condition: ModelCreditPublicRecordConditionInput
  ) {
    updateCreditPublicRecord(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
export const deleteCreditPublicRecord = /* GraphQL */ `
  mutation DeleteCreditPublicRecord(
    $input: DeleteCreditPublicRecordInput!
    $condition: ModelCreditPublicRecordConditionInput
  ) {
    deleteCreditPublicRecord(input: $input, condition: $condition) {
      id
      tenantOrganizationGroup
      owner
      caseId
      borrowerId
      publicRecordType
      filedDate
      dispositionType
      dispositionDate
      legalObligationAmount
      isOverridden
      createdAt
      updatedAt
      updatedBy
    }
  }
`;
